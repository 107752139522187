import React from "react"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import FullWidthImage from "../components/full-width-image"
import SEO from "../components/seo"
import ContentWrapper from "../components/content-wrapper"

const AboutPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO page="about" />
    <FullWidthImage page="about" />
    <ContentWrapper>
      <h1>
        <FormattedMessage id="about" />
      </h1>
      <h2>
        <FormattedMessage id="initial_idea_headline" />
      </h2>
      <p>
        <FormattedMessage id="initial_idea_text" />
      </p>

      <h2>
        <FormattedMessage id="our_mission_headline" />
      </h2>
      <p>
        <FormattedMessage id="our_mission_text" />
      </p>

      <h2>
        <FormattedMessage id="our_partners_in_brazil" />
      </h2>
      <p>
        Flausino Batista <br />
        <a href="mailto:batista@eurobramquality.com.br">
          batista@eurobramquality.com.br
        </a>
        <br />
        Tel: +55-11-98539 2741
      </p>
    </ContentWrapper>
  </Layout>
)

export default AboutPage
